import img1 from '../images/slider/img_slider_1.jpg';    
import img2 from '../images/common/project_1.png';  
import img3 from '../images/common/project_2.png';  
import img4 from '../images/common/project_3.png';  

const dataProject = [
    // Assuming the first 5 entries are placeholders or other projects not specified
    // They have been kept as-is for the sake of this example
    { id: 1, img: img1 },  
    { id: 2, img: img1 },  
    { id: 3, img: img1 },  
    { id: 4, img: img1 },  
    { id: 5, img: img1 },  

    // Updating the 6th entry for Bit Rivals with detailed info
    {
        id: 6, 
        img: img2, // Assuming img2 is the correct image for Bit Rivals
        title: "Bit Rivals",
        desc: "BitRivals brings gaming and earning together. Compete, conquer, and claim your victory.",
        price_1: "360,144 Tokens", // Updated with the total number of tokens for sale
        price_2: "$3,000 Max", // Updated with the max amount being raised
        price_3: "$30 Min Buy", // Updated with the minimum buy in DAI
    },

    // Updating the 7th entry for Haven's Compass with detailed info
    {
        id: 7, 
        img: img3, // Assuming img3 is the correct image for Haven's Compass
        title: "Haven's Compass",
        desc: "Haven's Compass is set in a future world. Engage in tournaments to reach safe havens!",
        price_1: "683,615 Tokens", // Updated with the total number of tokens for sale
        price_2: "$8,887 Max", // Updated with the max amount being raised
        price_3: "$5 Min Buy", // Updated with the minimum buy in DAI
    },

    // Assuming the 8th entry is a placeholder for future projects
    {
        id: 8, 
        img: img4, // Assuming img4 is a placeholder or generic image
        title: "Coming Soon",
        desc: "Reach out to inquire about featuring your project here.",
        price_1: "TBA",
        price_2: "TBA",
        price_3: "TBA",
    },
];

export default dataProject;
