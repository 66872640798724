import React from 'react';

import PageTitle from '../components/pagetitle';
import CTA from '../components/cta/cta_v2';

import icon from '../assets/images/common/down.svg'




function Roadmap(props) {
    return (
        <div className='inner-page'>
            {<PageTitle title='RoadMap' />}

            <section className="tf-section roadmap">
                <div className="container">
                    <div className="row"> 
                        <div className="col-md-12"> 
                            <div className="roadmap-wrapper-style2" data-aos="fade-up" data-aos-duration="1200">
                                <div className="left">
                                    <div className="roadmap-box-style active">
                                        <div className="icon">
                                            <img src={icon} alt="" />
                                        </div>
                                        <div className="content">
                                            <h6 className="date">Q3. 2023</h6>
                                            <ul>
                                                <li>Launch Socials</li>
                                                <li>Start Marketing</li>
                                                <li>Launch token</li>
                                                <li>Onboard Eth Bros</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="roadmap-box-style active">
                                        <div className="icon">
                                            <img src={icon} alt="" />
                                        </div>
                                        <div className="content">
                                            <h6 className="date">Q4. 2023</h6>
                                            <ul>
                                                <li>CG & CMC Listing</li>
                                                <li>Marketing Push</li>
                                                <li>Launch Burn Bot</li>
                                                <li>Mega Millions</li>
                                                <li>MEGA Society Infrastructure</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="right">
                                    <div className="roadmap-box-style active">
                                        <div className="icon">
                                            <img src={icon} alt="" />
                                        </div>
                                        <div className="content">
                                            <h6 className="date">Q2. 2024</h6>
                                            <ul>
                                                <li>Initial Pass TGE</li>
                                                <li>Release of Megapad</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="roadmap-box-style active">
                                        <div className="icon">
                                            <img src={icon} alt="" />
                                        </div>
                                        <div className="content">
                                            <h6 className="date">Q3. 2024</h6>
                                            <ul>
                                                <li>MEGABOT</li>
                                                <li>Different Blockchain Conferences</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </section>
            {<CTA />}
            
        </div>
    );
}

export default Roadmap;
