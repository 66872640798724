import React from 'react';

import PageTitle from '../components/pagetitle';
import CTA from '../components/cta/cta_v2';

function Contact(props) {
    return (
        <div className='inner-page'>
            <PageTitle title='Contact' />

            <section className="tf-section project-info">
                <div className="container"> 
                    <div className="row">
                        <div className="col-md-12">
                            {/* Update the form action to your Formspree endpoint */}
                            <form action="https://formspree.io/f/xyyrbeqb" method="POST" className="form-contact">
                                <div className="project-info-form">
                                    <h6 className="title">Get in Touch</h6>
                                    <div className="form-inner">
                                        <fieldset>
                                            <label>
                                                Full name
                                            </label>
                                            {/* Add the 'name' attribute for Formspree */}
                                            <input type="text" id="name" name="name" placeholder="Enter your full name" required />
                                        </fieldset>
                                        <fieldset>
                                            <label>
                                                Email address
                                            </label>
                                            {/* Add the 'name' attribute for Formspree */}
                                            <input type="email" id="email" name="_replyto" placeholder="Your email" required />
                                        </fieldset>
                                        <fieldset>
                                            <label>
                                                Phone
                                            </label>
                                            {/* Add the 'name' attribute for Formspree */}
                                            <input type="number" id="phone" name="phone" placeholder="Your phone" required />
                                        </fieldset>
                                        <fieldset>
                                            <label htmlFor="message">
                                                Message
                                            </label>
                                            {/* Ensure the 'name' attribute is set for the textarea as well */}
                                            <textarea id="message" name="message" placeholder="Write Your Message Here" rows="5" tabIndex="4" required></textarea>
                                        </fieldset> 
                                    </div>
                                </div> 

                                <div className="wrap-btn">
                                    <button type="submit" className="tf-button style1">
                                        Send message
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <CTA />
            
        </div>
    );
}

export default Contact;
