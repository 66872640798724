import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export function Chart({ data }) {
    if (!data || data.length === 0) {
        return <div>No data available for the chart.</div>;
    }

    const chartData = {
        labels: data.map(item => item.title),
        datasets: [{
            data: data.map(item => parseFloat(item.unit.replace('%', ''))),
            backgroundColor: ['#AFC5FF', '#FDEBB3', '#ACF2C4', '#CDBDF3', '#E89F8E', '#6574EB', '#ACE5F2'],
            borderColor: ['#AFC5FF', '#FDEBB3', '#ACF2C4', '#CDBDF3', '#E89F8E', '#6574EB', '#ACE5F2'],
            borderWidth: 1,
        }],
    };

    return <Doughnut data={chartData} />;
}
