const dataRoadmap = [
    {
        id: 1,
        time: 'Q3. 2023',
        text1: 'Launch Socials',
        text2: 'Start Marketing',
        text3: 'Launch token',
        status: 'active'
    }, 
    {
        id: 2,
        time: 'Q4. 2023',
        text1: 'CG & CMC Listing',
        text2: 'Marketing Push',
        text3: 'Launch Burn Bot',
        status: 'active'
    },
    {
        id: 3,
        time: 'Q4.5 2023',
        text1: 'Mega Millions',
        text2: 'MEGA Society Infrastructure',
        text3: 'MEGA Society Pass',
        status: 'active'
    },
    {
        id: 4,
        time: 'Q1. 2024',
        text1: 'MEGABOT',
        text2: 'Bitcoin Conference',
        text3: 'Initial Pass TGE',
        status: 'active'
    },
    {
        id: 5,
        time: 'Q2. 2024',
        text1: 'Cross Chain',
        text2: 'Moon Landing 1000x for all the believers',
        text3: '',
        status: '' // Assuming this status is meant to be empty as per original object for id 5
    }
    // Note: The provided text seems to be missing information for ids 6-8.
];

export default dataRoadmap;
