import React from 'react';

import img from '../../assets/images/backgroup/bg_project2.png'
import img1 from '../../assets/images/common/counter_1.png'
import img2 from '../../assets/images/common/counter_2.png'
import img3 from '../../assets/images/common/counter_3.png'
import img4 from '../../assets/images/common/counter_4.png'
import icon1 from '../../assets/images/chart/color_1.png'
import icon2 from '../../assets/images/chart/color_2.png'
import icon3 from '../../assets/images/chart/color_3.png'
import icon4 from '../../assets/images/chart/color_4.png'

import bg from '../../assets/images/chart/subtract.png'
import { useState } from 'react';

import { Chart } from './Chart';



function Token(props) {

    const [data] = useState([
        {
            id: 1,
            img: img1,
            title: 'DEXTOOLS',
            number: '',
            url: 'https://www.dextools.io/' // Example link, replace with the actual URL
        },
        {
            id: 2,
            img: img2,
            title: 'Find Us on X',
            number: '',
            url: 'https://twitter.com/PLSMEGA89815' // Replace with your actual link
        },
        {
            id: 3,
            img: img3,
            title: 'Telegram',
            number: '',
            url: 'https://t.me/PLSMEGA' // Example link, replace with your actual Telegram URL
        },
        {
            id: 4,
            img: img4,
            title: 'MEGA Society Pass',
            number: '',
            url: 'https://pulsemarket.app/collection/0x797070501c4dfd22962a426346e9ad95d91b216f' // Replace with your actual link, if applicable
        }
        ]
    )

    const [list] = useState(
        [
            {
                id: 1,
                icon: icon1,
                title: 'Team',
                unit: '2%'
            },
            {
                id: 2,
                icon: icon2,
                title: 'Staking',
                unit: '5%'
            },
            {
                id: 3,
                icon: icon3,
                title: 'Advisors',
                unit: '1%'
            },
            {
                id: 4,
                icon: icon4,
                title: 'Marketing',
                unit: '5.0%'
            },
   

        ]
    )

    const [dataTitle] = useState(
        {
            title: 'MEGA token',
  
        }
    )
    return (
        <section className="tf-section tf-token">
                <div className="overlay">
                    <img src={img} alt="MEGA" />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tf-title" data-aos="fade-up" data-aos-duration="800">
                                <h2 className="title">
                                    {dataTitle.title}
                                </h2>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="row">
                            <div className="counter_wrapper">
    {data.map(item => (
        <div key={item.id} className="box">
            <div className="image">
                <img src={item.img} alt={item.title} />
            </div>
            <div className="content">
                {/* Wrap the title with an anchor tag to make it a clickable link */}
                <p className="desc">
                    <a href={item.url} target="_blank" rel="noopener noreferrer">{item.title}</a>
                </p>
                <div className="box-couter counter">
                    <div className="number-content">
                        <span className="count-number" data-to="359" data-speed="2000" data-inviewport="yes">{item.number}</span>
                    </div>
                </div>
            </div>
        </div>
    ))}
</div>
                                <div className="char_wrapper">
                                    <ul className="describe_chart">
                                        {
                                            list.map(idx => (
                                                <li key={idx.id}>
                                                    <img src={idx.icon} alt="" />
                                                    <div className="desc">
                                                        <p className="name">{idx.title}</p>
                                                        <p className="number">{idx.unit}</p>
                                                    </div>
                                                </li>
                                            ))
                                        }
                                        
                                    </ul>
                                    <div className="chart_inner" data-aos="fade-up" data-aos-duration="800">
    <div className="content_inner">
        <img src={bg} alt="" />
        <p>MEGA token</p>
    </div>
    {/* Pass the 'list' state as 'data' prop to the Chart */}
    <Chart data={list} />
</div>
                                </div>
                            </div>
                        
                        </div>
                    </div>
                </div>
            </section>
    );
}

export default Token;