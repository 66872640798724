// TokenBar.js
import React from 'react';
import './bar.css'; // Ensure that bar.css is in the same directory as TokenBar.js

const TokenBar = () => {
  return (
    <div className="token-bar">
      <div className="token-box">
        <span className="token-name">EESEE</span>
        <span className="token-multiplier">20x</span>
      </div>
      {/* You can duplicate the above div for each token you want to display */}
    </div>
  );
};

export default TokenBar;
