import img1 from '../images/partner/01.png';
import img2 from '../images/partner/02.png';
import img3 from '../images/partner/03.png';
import img4 from '../images/partner/04.png';


const dataPartner = [
    {
        id: 1,
        img: img1
    }, 
    {
        id: 2,
        img: img2
    }, 
    {
        id: 3,
        img: img3
    }, 
    {
        id: 4,
        img: img4
    }, 
]

export default dataPartner;