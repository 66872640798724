import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../button';

function Banner(props) {
    const { item } = props;

    return (
        <div className="project-box">
            <div className="image">
                <img className="mask" src={item.img} alt={item.title} />
                <div className="shape">
                    <img src={require('../../../assets/images/common/shape.png')} alt="Shape" />
                </div>
            </div>
            <div className="content">
                <span className="border"></span>
                <div className="content-inner">
                    <h5 className="heading"><Link to="/project_list">{item.title}</Link></h5>
                    <p className="desc">{item.desc}</p>
                    <ul>
                        {item.price_1 && (
                            <li>
                                <p className="text">Tokens for Sale:</p>
                                <p className="price">{item.price_1}</p>
                            </li>
                        )}
                        {item.price_2 && (
                            <li>
                                <p className="text">Max Raise:</p>
                                <p className="price">{item.price_2}</p>
                            </li>
                        )}
                        {item.price_3 && (
                            <li>
                                <p className="text">Min Buy-in:</p>
                                <p className="price">{item.price_3}</p>
                            </li>
                        )}
                    </ul>
                    <p className="social_title">Social</p>
                    <ul className="social">
                        {/* Social icons and links here */}
                    </ul>
                </div>
            </div>
            <Button title='EXPLORE' path='/' addclass='style1' />
        </div>
    );
}

export default Banner;
