const menus = [
    {
        id: 1,
        name: 'Home',
        links: '/',
    },
    {
        id: 2,
        name: 'Mega',
        links: '#',
        namesub: [
            {
                 id: 1,
                 sub: 'NFT Staking',
                 links: 'https://megalaunchpad.pages.dev/'
             },
             {
                 id: 2,
                 sub: 'Token Staking ',
                 links: 'https://megalaunchpad.pages.dev/Staking'
             },
             {
                 id: 3,
                 sub: "Launchpad",
                 links: 'https://megalaunchpad.pages.dev/Launchpad'
             },
             {
                 id: 4,
                 sub: "Mega Lottery",
                 links: 'https://mega-lottery.vercel.app/'
             },
         ]
     },
    
    {
        id: 3,
        name: 'ICOs',
        links: '#',
        namesub: [
           {
                id: 1,
                sub: 'eesee.io',
                links: 'https://eesee.io'
            },
            {
                id: 2,
                sub: 'Bit Rivals ',
                links: 'https://www.bitrivals.app/join'
            },
            {
                id: 3,
                sub: "Haven's Compass",
                links: 'https://www.havenscompass.com/'
            },
        ]
    },
   
   
    {
        id: 4,
        name: 'Roadmap',
        links: '/roadmap',
    },
    {
        id: 5,
        name: 'Contact',
        links: '/contact',
    },
];

export default menus;
