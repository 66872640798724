import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import '../styles.scss';

Project.propTypes = {
    data: PropTypes.array,
};

function Project(props) {
    const { data } = props;

    const [dataTitle] = useState({
        title: 'Featured Projects',
        title_2: ' '
    });

    return (
        <section className="tf-section project">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="tf-title" data-aos="fade-up" data-aos-duration="800">
                            <h2 className="title">
                                {dataTitle.title}<br className="show-destop"/> {dataTitle.title_2}
                            </h2>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="project-wrapper">
                            <div className="image-wrapper" data-aos="fade-in" data-aos-duration="1000">
                                <div className="slider-1">
                                    <Swiper 
                                        modules={[A11y]}
                                        spaceBetween={0}
                                        slidesPerView={1}
                                    >
                                        {data.slice(0,5).map(item => (
                                            <SwiperSlide key={item.id} item={item}>
                                                <img src={item.img} alt="MEGA" />
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>
                            </div>
                            <div className="content-wrapper">
                                <div className="content_inner" data-aos="fade-left" data-aos-duration="1200">
                                    <div className="wrapper">
                                        <h4>EESEE</h4>
                                        <p className="desc">
                                            EESEE is a next-gen gamified NFTFi & SocialFi platform and marketplace that accelerates sales, provides additional liquidity and trading volume, lowers the cost-barrier to enter the market and facilitates community growth. Starting with Web3 assets and also integrating Web2 assets and tokenized real world assets (RWA's).
                                        </p>
                                        <ul className="price">
                                            <li>
                                                <span>Tokens for Sale: 41,666.6</span>
                                            </li>
                                            <li>
                                                <span>Max Raise: $10,000</span>
                                            </li>
                                            <li>
                                                <span>Min Buy-in: $5</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Project;
