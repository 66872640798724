import img1 from '../images/common/team_1.png';
import img2 from '../images/common/team_2.png';
import img3 from '../images/common/team_3.png';
import img4 from '../images/common/team_4.png';
import img5 from '../images/common/team_5.png';
import img6 from '../images/common/team_6.png';



const dataTeam = [
    {
        id: 1,
        img : img1,
        name: 'DegenGemHunter',
        unit: 'Team Member'
    }, 
    {
        id: 2,
        img : img2,
        name: 'MrDegen',
        unit: 'Team Member'
    }, 
    {
        id: 3,
        img : img3,
        name: 'MegaMan',
        unit: 'Team Member'
    }, 
    {
        id: 4,
        img : img4,
        name: 'Kryptolos',
        unit: 'Team Member'
    }, 
    {
        id: 5,
        img : img5,
        name: 'Criptobro',
        unit: 'Team Member'
    }, 
    {
        id: 6,
        img : img6,
        name: 'Jorge Hernandez',
        unit: 'Advisor'
    }, 
 
]

export default dataTeam;