import React, { useEffect, useState } from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';

function Footer() {
    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const scrollToSection = (event) => {
        event.preventDefault();
        const section = document.querySelector('#team-section');
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    useEffect(() => {
        const toggleVisibility = () => {
            if (window.pageYOffset > 500) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener("scroll", toggleVisibility);

        return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    return (
        <footer id="footer">
            <div className="footer-main">
                <div className="container">
                    <div className="row">
                        {/* Other footer content */}
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="wrap-fx">
                        {/* Other footer bottom content */}
                    </div>
                </div>
            </div>

            <div className="widget link">
                <h5 className="widget-title">Quick link</h5>
                <ul className="widget-link">
                    <li><Link to="/">Home</Link></li>
                    {/* Other links */}
                    <li><a href="#team-section" onClick={scrollToSection}>Our Team</a></li> {/* Updated Our Team link */}
                </ul>
            </div>

            {isVisible && <Link onClick={scrollToTop} to="#" id="scroll-top"></Link>}
        </footer>
    );
}

export default Footer;
